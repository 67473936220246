<style lang="scss">
</style>

<template>
  <div>
    <v-btn
      @click="onStarClick"
      class="ma-1"
      v-bind="{ rounded: true, color: 'white', ...$attrs }"
      :title="!bookmarked ? `Jälgi tööpakkumist` : `Eemalda jälgitavate tööpakkumiste hulgast`"
    >
      <slot v-bind:bookmarked="bookmarked" name="default">
        <v-icon v-bind="iconAttrs" v-text="`mdi-star${!bookmarked ? '-outline' : ''}`">
          mdi-star-outline
        </v-icon>
        Jälgi
      </slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    joblisting: {
      type: Object,
      required: true,
    },
    iconAttrs: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.user.id !== null;
    },
    bookmarked() {
      if (!this.isAuthenticated) return false;

      return this.$store.state.auth.user.bookmarked_joblisting_ids.includes(this.joblisting.id);
    },
  },
  methods: {
    onStarClick() {
      if (this.isAuthenticated) {
        this.post();
        return;
      }

      this.$store.commit("auth/SET", {
        dialogHeaderText: this.$store.getters.content("bookmarked_joblistings.please_authenticate"),
        showDialog: true,
      });
    },

    async post() {
      this.loading = true;
      const isBookmarked = !this.bookmarked;
      try {
        const response = await this.axios.post(`joblisting/${this.joblisting.id}/bookmark`, {
          is_bookmarked: isBookmarked,
        });
        this.$store.commit("auth/SET", { user: response.data.user });
        if (response.data.message)
          this.$notify(response.data.message, "success", {
            color: "#071b24",
            icon: isBookmarked ? "mdi-star" : "mdi-minus-circle",
          });
      } catch (error) {
        this.$catchApiErrors(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
