<template>
  <article class="joblisting-list-item d-flex image-gradient__wrap">
    <v-sheet>
      <v-img
        @click="onLinkClick ? onLinkClick() : $router.push(goToRoute)"
        class="elevation-1 cursor-pointer mt-1"
        width="200px"
        :max-width="$vuetify.breakpoint.xs ? '100px' : '200px'"
        :max-height="$vuetify.breakpoint.xs ? '70px' : '125px'"
        :aspect-ratio="200 / 125"
        :src="
          joblisting.list_image_src ? joblisting.list_image_src : joblisting.business_list_image
        "
      >
        <div class="image-gradient" />
        <base-btn
          v-if="joblisting.is_featured && $vuetify.breakpoint.smAndUp"
          x-small
          v-bind="{ tile: true, depressed: true, dark: true }"
          readonly
          :ripple="false"
          color="yellow darken-3 mx-1"
          tag="div"
        >
          <v-icon x-small class="mr-1">mdi-star</v-icon>
          Esiletõstetud
        </base-btn>
        <v-avatar
          class="position-absolute"
          style="bottom: 6px; left: 6px; z-index: 2"
          color="white"
          size="60"
        >
          <v-sheet color="white">
            <v-img
              max-width="54px"
              max-height="54px"
              contain
              :src="joblisting.business_logo_avatar"
            ></v-img>
          </v-sheet>
        </v-avatar>
      </v-img>
      <v-chip
        v-if="joblisting.is_featured && $vuetify.breakpoint.xs"
        x-small
        v-bind="{ tile: true, depressed: true, dark: true }"
        readonly
        :ripple="false"
        color="yellow darken-3 px-1 text-uppercase"
        style="border-radius: 0; width: 100px"
        tag="div"
      >
        <v-icon x-small class="mr-1">mdi-star</v-icon>
        Esiletõstetud
      </v-chip>
    </v-sheet>
    <v-sheet class="ml-2 flex-grow-1">
      <div class="d-sm-flex align-center h-100">
        <div class="h-100 pl-2 w-100">
          <div class="w-100">
            <div class="d-md-flex justify-space-between align-center">
              <router-link
                class="link cursor-pointer"
                :is="onLinkClick ? 'span' : 'router-link'"
                :to="onLinkClick ? null : goToRoute"
                @click="onLinkClick ? onLinkClick() : null"
              >
                <h4 class="text-h5">
                  {{ joblisting.title }}
                </h4>
              </router-link>
              <JoblistingDetailChips
                :wrap="wrapDetailsChips"
                class="my-2 my-md-0"
                x-small
                :joblisting="joblisting"
              />
            </div>
            <div class="red--text text--darken-4 text-subtitle-2 mb-1">
              <span>@ {{ joblisting.business_title }}</span>
            </div>

            <div class="mb-1" v-if="showExcerpt">
              <small>
                <v-clamp
                  autoresize
                  class="text-body-2 text--secondary text-body-2"
                  :max-lines="2"
                  style="max-width: 500px"
                >
                  {{ joblisting.business_excerpt }}
                </v-clamp>
              </small>
            </div>

            <small v-if="deadline" class="text--secondary text-no-wrap d-sm-none">
              {{ deadline }}
            </small>

            <div class="text--disabled d-flex align-center mb-2" v-if="joblisting.location">
              <v-icon color="grey lighten-1" x-small>mdi-map-marker</v-icon>
              <small>{{ joblisting.location }}</small>
            </div>

            <div>
              <v-chip
                small
                label
                class="mr-2 mb-2"
                color="blue lighten-5"
                v-for="tag in joblisting.tags_array"
                :key="tag"
              >
                {{ tag }}
              </v-chip>
            </div>
          </div>
          <slot name="footer"></slot>
        </div>

        <div v-if="deadline" class="h-100 d-none d-sm-flex flex-column align-end">
          <!-- <base-btn
            v-if="joblisting.is_featured"
            x-small
            v-bind="{ tile: true, depressed: true, dark: true }"
            :class="`cursor-default`"
            readonly
            :ripple="false"
            color="yellow darken-3"
            tag="div"
          >
            <v-icon x-small class="mr-1">mdi-star</v-icon>
            Esiletõstetud
          </base-btn> -->

          <div class="pa-1 pt-0" :title="`Kandideerimise tähtaeg: ${deadline}`">
            <small class="text--secondary text-no-wrap">
              {{ deadline }}
            </small>
          </div>
          <BookmarkJoblisting
            :joblisting="joblisting"
            small
            :icon-attrs="{ small: true }"
          ></BookmarkJoblisting>
        </div>
      </div>
    </v-sheet>
  </article>
</template>

<script>
import BookmarkJoblisting from "../../BookmarkJoblisting.vue";
import JoblistingDetailChips from "../JoblistingDetailChips.vue";
import NarrowListItemWithIcon from "../NarrowListItemWithIcon.vue";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { et } from "date-fns/locale";

export default {
  components: {
    NarrowListItemWithIcon,
    JoblistingDetailChips,
    BookmarkJoblisting,
  },
  props: {
    joblisting: {
      type: Object,
      required: true,
    },
    goToRouteName: {
      type: String,
      default: null,
    },
    onLinkClick: {
      type: Function,
      default: null,
    },
    showDeadline: {
      type: Boolean,
      default: true,
    },
    showExcerpt: {
      type: Boolean,
      default: true,
    },
    wrapDetailsChips: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    goToRoute() {
      return {
        name: this.goToRouteName ? this.goToRouteName : "ShowJoblisting",
        params: {
          business_slug: this.joblisting.business_slug,
          joblisting_id: this.joblisting.id,
        },
      };
    },
    deadline() {
      if (!this.showDeadline) return false;
      return this.formatDate(this.joblisting.deadline);
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      try {
        return format(parseISO(date), "d. MMMM yyyy", { locale: et });
      } catch (error) {
        return null;
      }
    },
  },
};
</script>
