<style lang="scss">
</style>

<template>
  <div>
    <v-btn
      @click="onStarClick"
      class="ma-1"
      v-bind="$attrs"
      :large="large"
      icon
      :title="!starred ? `Lisa lemmikuks` : `Eemalda lemmikutest`"
    >
      <v-icon :large="large" v-text="`mdi-star${!starred ? '-outline' : ''}`"></v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    business: {
      type: Object,
      required: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.user.id !== null;
    },
    starred() {
      if (!this.isAuthenticated) return false;

      return this.$store.state.auth.user.bookmarked_business_ids.includes(this.business.id);
    },
  },
  methods: {
    onStarClick() {
      if (this.isAuthenticated) {
        this.post();
        return;
      }

      this.$store.commit("auth/SET", {
        dialogHeaderText: this.$store.getters.content("bookmarked_businesses.please_authenticate"),
        showDialog: true,
      });
    },

    async post() {
      this.loading = true;
      const isBookmarked = !this.starred;
      try {
        const response = await this.axios.post(`business/${this.business.slug}/bookmark`, {
          is_bookmarked: isBookmarked,
        });
        this.$store.commit("auth/SET", { user: response.data.user });
        if (response.data.message)
          this.$notify(response.data.message, "success", {
            color: "#071b24",
            icon: isBookmarked ? "mdi-star" : "mdi-minus-circle",
          });
      } catch (error) {
        this.$catchApiErrors(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
