
<template>
  <div>
    <BusinessBannerDesktop v-bind="$attrs" v-if="$vuetify.breakpoint.mdAndUp" />
    <BusinessBannerMobile v-bind="$attrs" v-else />
  </div>
</template>

<script>
import BusinessBannerMobile from "./BusinessBannerMobile";
import BusinessBannerDesktop from "./BusinessBannerDesktop";

export default {
  components: {
    BusinessBannerDesktop,
    BusinessBannerMobile,
  },
};
</script>
