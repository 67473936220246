<template>
  <div>
    <v-img :src="business.banner_image" height="300" class="elevation-1 grey darken-4">
      <v-container class="h-100">
        <v-row class="h-100" align="end">
          <v-col cols="12">
            <v-card elevation="24" color="white" class="d-flex justify-space-between">
              <div class="position-absolute" style="bottom: -15px; left: 15px">
                <v-avatar size="150" color="white" class="d-flex align-center h-100">
                  <v-sheet class="d-flex h-100">
                    <v-img
                      max-width="150"
                      width="150"
                      max-height="150"
                      height="150"
                      contain
                      :src="business.logo_thumbnail"
                      :lazy-src="business.logo_avatar"
                    ></v-img>
                  </v-sheet>
                </v-avatar>
              </div>
              <div class="d-flex align-center">
                <v-sheet width="185px">
                  <!-- This keeps the prosition of text block - do not remove! -->
                </v-sheet>
                <div>
                  <h1 class="text-h5 font-weight-medium">
                    {{ title ? title : business.title }}
                  </h1>
                  <em class="text--secondary">
                    {{ subtitle ? subtitle : business.slogan }}
                  </em>
                </div>
              </div>
              <div class="d-flex align-center">
                <div class="ma-2">
                  <v-img
                    max-width="200"
                    style="width: 200px"
                    contain
                    src="/storage/base/trust-logo/ehitussektor-soovitab-2022.png"
                    @click="$router.push($store.getters.setting('trustmark_path'))"
                    class="cursor-pointer"
                  ></v-img>
                </div>
                <div class="ma-2">
                  <BookmarkBusiness :business="business" large color="black" />
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </div>
</template>

<script>
import BookmarkBusiness from "@/blocks/business/components/BookmarkBusiness";
export default {
  components: {
    BookmarkBusiness,
  },
  props: {
    business: {
      type: Object,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
};
</script>
