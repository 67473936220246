<style lang="scss">
</style>

<template>
  <v-row justify="center">
    <v-col
      cols="12"
      :md="business.intro_video_url || business.intro_image ? 6 : 12"
      class="flex-grow"
    >
      <base-card elevation="0" class="h-100">
        <div class="text-body-1 pa-4">
          <div v-html="business.description"></div>
          <div class="text-body-2 text--secondary mt-10 pt-5">
            <div class="mb-1" v-if="business.categories.length">
              <em>
                {{ business.categories.length === 1 ? "Kategooria: " : "Kategooriad: " }}
                <span
                  class="text-lowercase"
                  v-html="
                    '<strong>' +
                    $joinAsSentence(
                      business.categories.map((el) => el.title),
                      '</strong>, <strong>',
                      '</strong> ja <strong>',
                    ) +
                    '</strong>.'
                  "
                />
              </em>
            </div>
            <div v-if="business.tags.length">
              <em>
                {{ business.tags.length === 1 ? "Märksõna: " : "Märksõnad: " }}
                <span
                  v-html="
                    '<strong>' +
                    $joinAsSentence(
                      business.tags.map((el) => el.name),
                      '</strong>, <strong>',
                      '</strong> ja <strong>',
                    ) +
                    '</strong>.'
                  "
                />
              </em>
            </div>
          </div>
          <slot name="buttons">
            <div class="text-center pt-2">
              <base-btn
                v-if="business.offerings.length"
                class="ma-3"
                color="black"
                elevation="0"
                dark
                @click="$vuetify.goTo('#business-offerings')"
              >
                Loe edasi
                <v-icon>mdi-chevron-down</v-icon>
              </base-btn>
              <base-btn
                v-if="hasContactsArea"
                class="ma-3"
                outlined
                text
                @click="$vuetify.goTo('#contact-us')"
              >
                Vaata kontaktandmeid
              </base-btn>
            </div>
          </slot>
        </div>
      </base-card>
    </v-col>

    <v-col
      cols="12"
      md="6"
      class="d-flex justify-center"
      v-if="business.intro_video_url || business.intro_image"
    >
      <v-sheet
        max-width="650px"
        max-height="100%"
        height="315px"
        width="560px"
        v-if="business.intro_video_url"
      >
        <iframe
          width="100%"
          height="100%"
          :src="parsedIntroVideoUrl"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-sheet>
      <img
        class="d-none d-md-block"
        v-else
        :src="business.intro_image"
        :alt="business.title"
        contain
        max-height="350px"
        height="350px"
        width="100%"
      />
    </v-col>
  </v-row>
</template>

<script>
import { parseVideo } from "@/blocks/global/youtube-player/youtube-player-helpers.js";
export default {
  props: {
    business: {
      type: Object,
      required: true,
    },
    hasContactsArea: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parsedIntroVideoUrl() {
      if (!this.business.intro_video_url) {
        return null;
      }
      return parseVideo(this.business.intro_video_url).url;
    },
  },
};
</script>
