<style lang="scss">
</style>

<template>
  <div class="d-flex" :class="wrap ? 'flex-wrap' : 'flex-no-wrap'">
    <base-btn
      v-if="joblisting.workload"
      v-bind="{ tile: true, depressed: true, dark: true, ...$attrs }"
      :title="`Töökoormus: ${joblisting.workload}`"
      :class="`mr-${xSpacing} cursor-default mb-1`"
      readonly
      :ripple="false"
      color="blue darken-1"
      tag="div"
    >
      <span class="d-inline-block text-truncate" style="max-width: 100px">
        {{ joblisting.workload }}
      </span>
    </base-btn>

    <base-btn
      v-bind="{ tile: true, depressed: true, dark: true, ...$attrs }"
      v-if="wageOffer"
      :title="`Brutotöötasu: ${wageOffer}`"
      :class="`mr-${xSpacing} cursor-default mb-1`"
      readonly
      :ripple="false"
      color="grey darken-1"
      tag="div"
    >
      {{ wageOffer }}
    </base-btn>
  </div>
</template>

<script>
export default {
  props: {
    joblisting: {
      type: Object,
      required: true,
    },
    xSpacing: {
      type: [Number, String],
      default: 2,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    wageOffer() {
      const wageOfferFrom = this.joblisting.wage_offer_from;
      const wageOfferTo = this.joblisting.wage_offer_to;

      if (!wageOfferFrom && !wageOfferTo) {
        return null;
      } else if (wageOfferFrom && wageOfferTo) {
        if (wageOfferFrom === wageOfferTo) return `${wageOfferFrom} €`;
        return `${[wageOfferFrom, wageOfferTo].join("-")} €`;
      } else if (wageOfferFrom) {
        return `al. ${wageOfferFrom} €`;
      } else if (wageOfferTo) {
        return `kuni ${wageOfferTo} €`;
      }
    },
  },
};
</script>