<style lang="scss">
@import "@/dependencies.scss";
// @include media-breakpoint-down(sm) {
.admin-menu-btn {
  width: 150px;
}
// }
</style>

<template>
  <div>
    <div class="d-flex flex-wrap flex-column flex-sm-row">
      <base-btn
        v-if="showViewApplicationsLink"
        :to="joblistingApplicationsRoute"
        small
        color="blue"
        v-bind="item.jobapplications_count ? { dark: true } : { disabled: true }"
        class="admin-menu-btn ma-1"
        :title="`Vaata tööpakkumise andmeid`"
      >
        {{
          item.jobapplications_count +
          (item.jobapplications_count === 1 ? " kandidaat" : " kandidaati")
        }}
      </base-btn>

      <JoblistingPublishToggle
        class="admin-menu-btn ma-1"
        @updated="$emit('updated', $event)"
        :business="business"
        :item="item"
        small
      />

      <v-menu bottom tile left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <base-btn
            small
            color="black"
            dark
            class="admin-menu-btn ma-1 mr-0"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon small>mdi-dots-vertical</v-icon>
            Valikud
          </base-btn>
        </template>

        <v-list>
          <v-list-item :to="joblistingApplicationsRoute" v-if="showViewApplicationsLink">
            <v-list-item-title>
              <v-icon left>mdi-briefcase-account-outline</v-icon>
              Vaata kandidaate ({{ item.jobapplications_count }})
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="showJoblistingRoute" exact>
            <v-list-item-title>
              <v-icon left>mdi-eye</v-icon>
              Ava tööpakkumise leht
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="editJoblistingRoute">
            <v-list-item-title>
              <v-icon left>mdi-pencil</v-icon>
              Muuda tööpakkumist
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="destroyJoblisting">
            <v-list-item-title>
              <v-icon left>mdi-close</v-icon>
              Kustuta tööpakkumine
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import JoblistingPublishToggle from "./JoblistingPublishToggle.vue";
export default {
  components: {
    JoblistingPublishToggle,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
    showViewApplicationsLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    joblistingApplicationsRoute() {
      return {
        name: "JoblistingApplications",
        params: { business_slug: this.business.slug, joblisting_id: this.item.id },
      };
    },
    editJoblistingRoute() {
      return {
        name: "EditJoblisting",
        params: { business_slug: this.business.slug, joblisting_id: this.item.id },
      };
    },
    showJoblistingRoute() {
      return {
        name: "ShowJoblisting",
        params: { business_slug: this.business.slug, joblisting_id: this.item.id },
      };
    },
  },
  data() {
    return {
      destroying: false,
    };
  },
  methods: {
    async destroyJoblisting() {
      let response = await this.$dialog.confirm({
        title: "Tööpakkumise kustutamine",
        text:
          "Kas oled kindel, et soovid tööpakkumise " +
          '"' +
          this.item.title +
          '"' +
          " kustutada? Seda ei saa enam hiljem taastada!",
        actions: {
          false: "Ei, muutsin meelt",
          true: {
            text: "Jah",
            color: "primary",
          },
        },
      });

      if (!response) return;

      this.destroying = true;
      try {
        const response = await this.axios.delete(
          `business/${this.business.id}/joblisting/${this.item.id}`,
        );
        this.$notify(response.data.message, "success", {
          color: "#071b24",
          icon: "mdi-close",
          timeout: 12000,
        });
        if (response.data.deleted) this.$emit("destroyed", this.item.id);
      } catch (error) {
        this.$catchApiErrors(error);
      } finally {
        this.destroying = false;
      }
    },
  },
};
</script>