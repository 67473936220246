<template>
  <base-btn
    @click="onPublishClick(!item.is_published)"
    :loading="publishLoading"
    dark
    :color="item.is_published ? 'success' : 'grey'"
    :outlined="!item.is_published"
    class="activate-joblisting"
    v-bind="$attrs"
  >
    <span v-if="item.is_published">
      <v-icon left>mdi-toggle-switch</v-icon>
      Avaldatud
    </span>
    <span v-else>
      <v-icon left>mdi-toggle-switch-off</v-icon>
      Tee avalikuks
    </span>
  </base-btn>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      publishLoading: false,
    };
  },

  methods: {
    async onPublishClick(is_published) {
      this.publishLoading = true;
      const path = `/business/${this.business.id}/joblisting/${this.item.id}/publish`;

      try {
        const response = await this.axios.post(path, { is_published });
        this.$store.commit("joblisting/ADD_OR_UPDATE_ITEM", response.data.item);
        this.$notify(response.data.message);
        this.$emit("updated", response.data.item);
      } catch (error) {
        this.$catchApiErrors(error);
      } finally {
        this.publishLoading = false;
      }
    },
  },
};
</script>