<template>
  <div class="bottom-gradient__wrap">
    <v-img :src="business.banner_image" height="250" class="elevation-1">
      <div class="bottom-gradient"></div>
      <div class="position-relative h-100 d-flex flex-column justify-space-between">
        <div class="ma-1 d-flex justify-space-between">
          <div>
            <base-card
              class="pa-1 d-flex align-center"
              max-width="130px"
              :tile="false"
              color="white"
              elevation="12"
            >
              <v-img class width="130" contain :src="business.logo_thumbnail"></v-img>
            </base-card>
          </div>
          <v-avatar color="rgba(0,0,0,.5)">
            <BookmarkBusiness large color="white" :business="business" />
          </v-avatar>
        </div>
        <div class="ma-2 d-flex white--text">
          <div>
            <h1 class="text-h4 font-weight-medium">
              {{ title ? title : business.title }}
            </h1>
            <em>{{ subtitle ? subtitle : business.slogan }}</em>
          </div>
        </div>
      </div>
    </v-img>
    <div class="d-flex justify-space-around align-center">
      <div class="ma-2">
        <v-img
          max-width="120"
          style="width: 120px"
          contain
          src="/storage/base/trust-logo/ehitussektor-soovitab-2022.png"
          @click="$router.push($store.getters.setting('trustmark_path'))"
          class="cursor-pointer"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import BookmarkBusiness from "@/blocks/business/components/BookmarkBusiness";
export default {
  components: {
    BookmarkBusiness,
  },
  props: {
    business: {
      type: Object,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
};
</script>
