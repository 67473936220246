var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"flex-grow",attrs:{"cols":"12","md":_vm.business.intro_video_url || _vm.business.intro_image ? 6 : 12}},[_c('base-card',{staticClass:"h-100",attrs:{"elevation":"0"}},[_c('div',{staticClass:"text-body-1 pa-4"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.business.description)}}),_c('div',{staticClass:"text-body-2 text--secondary mt-10 pt-5"},[(_vm.business.categories.length)?_c('div',{staticClass:"mb-1"},[_c('em',[_vm._v(" "+_vm._s(_vm.business.categories.length === 1 ? "Kategooria: " : "Kategooriad: ")+" "),_c('span',{staticClass:"text-lowercase",domProps:{"innerHTML":_vm._s(
                  '<strong>' +
                  _vm.$joinAsSentence(
                    _vm.business.categories.map((el) => el.title),
                    '</strong>, <strong>',
                    '</strong> ja <strong>',
                  ) +
                  '</strong>.'
                )}})])]):_vm._e(),(_vm.business.tags.length)?_c('div',[_c('em',[_vm._v(" "+_vm._s(_vm.business.tags.length === 1 ? "Märksõna: " : "Märksõnad: ")+" "),_c('span',{domProps:{"innerHTML":_vm._s(
                  '<strong>' +
                  _vm.$joinAsSentence(
                    _vm.business.tags.map((el) => el.name),
                    '</strong>, <strong>',
                    '</strong> ja <strong>',
                  ) +
                  '</strong>.'
                )}})])]):_vm._e()]),_vm._t("buttons",function(){return [_c('div',{staticClass:"text-center pt-2"},[(_vm.business.offerings.length)?_c('base-btn',{staticClass:"ma-3",attrs:{"color":"black","elevation":"0","dark":""},on:{"click":function($event){return _vm.$vuetify.goTo('#business-offerings')}}},[_vm._v(" Loe edasi "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),(_vm.hasContactsArea)?_c('base-btn',{staticClass:"ma-3",attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.$vuetify.goTo('#contact-us')}}},[_vm._v(" Vaata kontaktandmeid ")]):_vm._e()],1)]})],2)])],1),(_vm.business.intro_video_url || _vm.business.intro_image)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"6"}},[(_vm.business.intro_video_url)?_c('v-sheet',{attrs:{"max-width":"650px","max-height":"100%","height":"315px","width":"560px"}},[_c('iframe',{attrs:{"width":"100%","height":"100%","src":_vm.parsedIntroVideoUrl,"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_c('img',{staticClass:"d-none d-md-block",attrs:{"src":_vm.business.intro_image,"alt":_vm.business.title,"contain":"","max-height":"350px","height":"350px","width":"100%"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }