export default {
  created() {
    this.mixinSetLocalClonedBusiness();
    this.mixinFetchBusiness();
  },

  data() {
    return {
      mixinLoadingBusiness: null,
      mixinBusinessNotFound: null,
      business: null,
    };
  },

  watch: {
    $route() {
      this.mixinForceFetchBusiness();
    },
  },

  computed: {
    /*
     * Determines if we already have full data of business or only basic data
     * that were used to index businesses.
     */
    mixinIsFinalBusiness() {
      return this.business && this.business.final;
    },
  },

  methods: {
    /**
     * * Clone local copy of the business.
     *
     * At first, there could only be business's basic information and
     * author, content etc will be fetched on demand, so we need
     * to clone new object to make sure everything re-renders
     * after full data of business is fetched.
     */
    mixinSetLocalClonedBusiness() {
      this.business = this._.cloneDeep(
        this.$store.getters["business/findBy"]("items", "slug", this.$route.params.business_slug),
      );
    },

    async mixinFetchBusiness() {
      if (this.mixinIsFinalBusiness) {
        return;
      }
      this.mixinForceFetchBusiness();
    },

    async mixinForceFetchBusiness() {
      this.mixinLoadingBusiness = true;
      try {
        const response = await this.axios.get("business/" + this.$route.params.business_slug);

        this.$store.commit("business/ADD_OR_UPDATE_ITEM", response.data);
        this.mixinSetLocalClonedBusiness();
      } catch (error) {
        if (error.response.status === 404) this.mixinBusinessNotFound = true;
      } finally {
        this.mixinLoadingBusiness = false;
      }
    },
  },
};
